$color-blue-new: #0040FA;
$color-blue-new-hover: #002696;
$color-blue-new-active: #668CFC;

$color-ground-black: #000000;
$color-silicium-blue: #282e44;
$color-sonnen-blue: #4dcff0;
$color-grey-blue: #5e6373;
$color-grey-10: #f1f1f1;
$color-grey-20: #e8e8e8;
$color-grey-30: #afb0b5;
$color-grey-50: #8b8c93;
$color-red: #DF1D35;
