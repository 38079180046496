@import '../../styles/breakpoint.mixin.scss';
@import '../../styles/colors.scss';

.mainHeader {
  background: linear-gradient(270deg, $color-blue-new 0%, #081435 50%);
  color: #fff;

  p {
    margin: 0;
  }

  a {
    color: #fff;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    height: 84px;
    padding: 24px;
  }

  &__navigation {
    display: flex;
    align-items: center;
  }

  &__search {
    margin-right: 20px;

    @include breakpoint('s') {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-right: 0;
      pointer-events: none;
    }
  }

  &__branding {
    max-height: 32px;
    display: flex;
    align-items: center;

    img {
      display: block;
      height: 100%;
      max-height: 72px;
      width: auto;
    }

    span {
      margin-left: 22px;
      padding-left: 22px;
      font-size: 1.125rem;
      font-family: SonnenDisplay;
      color: #fff;

      @include breakpoint('s') {
        margin-left: 14px;
        padding-left: 14px;
      }
    }
  }

  &__burger {
    display: none;

    svg {
      display: block;
    }

    @include breakpoint('l') {
      display: block;
    }
  }

  @include breakpoint('l') {
    position: sticky;
    top: 0;
    z-index: 2;

    &__avatar {
      display: none;
    }
  }
}
