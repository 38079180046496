@import '../../styles/colors.scss';

.search-results {
  &__result {
    margin-bottom: 60px;
    overflow-x: hidden;

    h2 {
      margin-bottom: 1rem;
    }

    &__excerpt {
      p {
        display: inline;
      }

      a {
        margin-left: 6px;
      }

      span {
        border-bottom: 2px solid rgba(77, 207, 240, 1);
        background: rgba(77, 207, 240, 0.2);
      }
    }
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__breadcrump {
    display: flex;
    align-items: center;
    margin-top: 12px;

    span,
    a {
      display: block;
      color: $color-grey-blue;
      font-weight: 500;
      margin: 0;
      text-transform: capitalize;
    }

    span {
      margin: 0 4px;
    }
  }
}
