@import '../../styles/colors.scss';

.sonnen-image-comparison {
  overflow: hidden;

  .image-comparison {
    &__container {
      position: relative;
      width: 100%;
      height: auto;

      img {
        display: block;
      }

      &__comparison {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
      }
    }

    &__range-container {
      width: 100%;
      text-align: center;

      input[type='range'] {
        width: 90%;
        min-width: 100px;
        margin: 0 auto 20px auto;
        cursor: pointer;
        -webkit-appearance: none;
        background: transparent;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
      }

      input[type='range']:focus {
        outline: none;
      }

      input[type='range']::-ms-track {
        width: 100%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: 1px solid $color-silicium-blue;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        margin-top: -12px;
      }

      /* All the same stuff for Firefox */
      input[type='range']::-moz-range-thumb {
        border: 1px solid $color-silicium-blue;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
      }

      /* All the same stuff for IE */
      input[type='range']::-ms-thumb {
        border: 1px solid $color-silicium-blue;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
      }

      input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        background: $color-silicium-blue;
      }

      input[type='range']::-moz-range-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        background: $color-silicium-blue;
      }

      input[type='range']::-ms-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        background: $color-silicium-blue;
        color: transparent;
      }
      // input[type='range']::-ms-fill-lower {
      //   background: #2a6495;
      //   border: 0.2px solid #010101;
      //   border-radius: 2.6px;
      //   box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      // }
      // input[type='range']:focus::-ms-fill-lower {
      //   background: #3071a9;
      // }
      // input[type='range']::-ms-fill-upper {
      //   background: #3071a9;
      //   border: 0.2px solid #010101;
      //   border-radius: 2.6px;
      //   box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
      // }
      // input[type='range']:focus::-ms-fill-upper {
      //   background: #367ebd;
      // }
    }
  }
}
