@import '../../styles/colors.scss';

.download-button {
  position: relative;

  [data-tippy-root] {
    width: 100%;
  }

  .tippy-box {
    background: #fff;
    border-radius: 16px;
    border: 1px solid #A3A6B2;
    padding: 10px 16px;
    width: 100%;
  }

  &__wrapper {
    padding: 16px 16px;
    border: 1px solid #A3A6B2;
    border-radius: 16px;
    transition: all 0.2s ease-out;
    text-align: center;
    cursor: pointer;
    outline: 1px solid white;
  }

  &__button {
    color: #565E73;
    font-weight: 500;
  }

  &:hover &__wrapper {
    border-color: #0040FA;
    outline: 1px solid #0040FA;
  }

  &:hover &__button {
    color: $color-blue-new-hover;
  }

  .source-list {
    display: flex;
    gap: 4px;
    flex-direction: column;

    a {
      display: block;
      width: 100%;
      color: #565E73;
      font-weight: 500;
      padding: 4px 8px;
      border-radius: 16px;

      &:hover {
        background-color: #E5ECFE;
        text-decoration: none;
      }
    }
  }
}
