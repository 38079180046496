@import '../../styles/colors.scss';

.media-search-bar {
  position: relative;
  width: 100%;

  input[type='text'] {
    width: 100%;
    height: auto;
    padding: 12px 18px;
    background: $color-grey-10;
    border: 0;
    border-radius: 24px;
    outline: 0;
    font-size: 0.7rem;
    line-height: 1rem;
    color: $color-grey-30;

    &::-ms-input-placeholder {
      line-height: 1rem;
      color: $color-grey-30;
    }
    &:-ms-input-placeholder {
      line-height: 1rem;
      color: $color-grey-30;
    }
    &::placeholder {
      line-height: 1rem;
      color: $color-grey-30;
    }
  }

  svg {
    display: block;
    stroke: $color-grey-30;
  }

  &__search-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
  }

  .loader {
    position: absolute;
    top: 11px;
    right: 10px;
  }
}
