form,
form button,
button {
  font-family: 'SonnenText', -apple-system, BlinkMacSystemFont, Helvetica Neue,
    sans-serif;
}

form input {
  &[type='text'],
  &[type='password'] {
    height: 48px;
    border-radius: 16px;
    background-color: #D9D9D9;
    border: 1px solid #D9D9D9;
    padding: 12px;
    font-size: 1.125rem;

    &::placeholder {
      color: #a2a4a9;
    }

    &:active,
    &:focus {
      border-color: $color-sonnen-blue;
      outline: none;
    }
  }
}

.fieldGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  label {
    color: #4a4a4a;
    font-size: 0.875rem;
    margin-bottom: 6px;
  }
}

form button {
  border: 2px solid $color-blue-new;
  border-radius: 16px;
  padding: 16px 32px;
  font-size: 1rem;
  cursor: pointer;
  background: $color-blue-new;
  color: #ffffff;
  min-width: 190px;

  &:hover {
    border-color: $color-blue-new-hover;
    background: $color-blue-new-hover;
  }

  &:active {
    background: $color-blue-new-active;
    border-color: $color-blue-new-active;
  }
}

.sonnen-button {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 2px solid $color-blue-new;
  padding: 12px 40px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    border-color: $color-sonnen-blue;
    color: $color-sonnen-blue;

    svg {
      rect,
      path,
      line,
      polyline {
        stroke: $color-sonnen-blue;
      }
    }
  }

  svg {
    display: block;
    user-select: none;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }

  span {
    font-weight: 500;
    white-space: nowrap;
    user-select: none;
  }
}
