@import '../../styles/colors.scss';
@import '../../styles/breakpoint.mixin.scss';

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(80deg, #00207D 2.58%, #0040FA 97.94%);

  &__container {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24);
    z-index: 2;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  &__header {
    background: $color-silicium-blue;
    padding: 14px 20px;

    img {
      display: block;
      width: 130px;
      height: auto;
    }
  }

  &__content {
    padding: 48px;
    text-align: center;

    form .fieldGroup {
      text-align: left;
    }
  }

  &__buttons {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;

    a {
      display: inline-block;
      margin-top: 12px;
      font-weight: 300;
    }
  }

  &__welcome {

    &__illustration {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 68px;
      height: 68px;
      background: linear-gradient(133deg, #0040FA 17.75%, #FF6D21 84.53%), #D9D9D9;
      border-radius: 100%;

      img {
        display: block;
        width: 69%;
        aspect-ratio: 1/1;
      }
    }

    p {
      font-size: 1.125rem;
      font-weight: 300;

      span {
        display: block;
      }
    }
  }

  &__error {
    color: #ff4a4a;
    font-weight: bold;
  }

  &__footer {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    background-color: #DBDCE1;

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      padding: 0 30px;
      margin: 0 auto;
      z-index: 1;

      @include breakpoint('s') {
        flex-direction: column;
      }

      p {
        color: #0A1535;
        margin: 0;
        font-size: 0.75rem;
      }

      a {
        color: #0A1535;
        margin: 0 10px;
        font-weight: 500;
        font-size: 0.75rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
