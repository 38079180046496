@import '../../styles/colors.scss';

.image-lightbox {
  &__preview-image {
    cursor: pointer;
  }

  &__lightbox-image {
    margin: 0 auto;
    .--portrait & {
      width: auto;
      height: 100%;
      max-height: 90vh;
    }
  }

  &__lightbox {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.95);
    z-index: 20;

    .--open & {
      display: block;
    }

    &__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  &__close-button {
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      background: transparent;
      color: $color-silicium-blue;
      font-weight: 700;
      font-size: 0.875rem;
      border: 0;

      svg {
        margin-left: 2px;
      }
    }
  }
}
