.search-results-page {
  hr {
    border: 0;
    border-top: 1px solid #c9cbd0;
  }

  &__term {
    font-weight: 700;
    text-transform: uppercase;
  }
}
