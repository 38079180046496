@font-face {
  font-family: 'SonnenDisplay';
  font-weight: 700;
  font-style: normal;
  src:  url('../static/fonts/SonnenDisplay-Bold.otf'),
        url('../static/fonts/SonnenDisplay-Bold.woff') format('woff'),
        url('../static/fonts/SonnenDisplay-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'SonnenText';
  font-weight: 300;
  font-style: normal;
  src:  url('../static/fonts/SonnenText-Light.otf'),
        url('../static/fonts/SonnenText-Light.woff') format('woff'),
        url('../static/fonts/SonnenText-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'SonnenText';
  font-weight: 500;
  font-style: normal;
  src:  url('../static/fonts/SonnenText-Regular.otf'),
        url('../static/fonts/SonnenText-Regular.woff') format('woff'),
        url('../static/fonts/SonnenText-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'SonnenText';
  font-weight: 700;
  font-style: normal;
  src:  url('../static/fonts/SonnenText-Bold.otf'),
        url('../static/fonts/SonnenText-Bold.woff') format('woff'),
        url('../static/fonts/SonnenText-Bold.woff2') format('woff2');
}