@import '../../styles/breakpoint.mixin.scss';
@import '../../styles/colors.scss';

.main-footer {
  background: $color-grey-20;
  padding: 16px 24px;
  margin-top: 10px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #646464;
    font-size: 0.75rem;
    max-width: 1440px;
    margin: 0 auto;

    @include breakpoint('s') {
      flex-direction: column;
    }
  }

  &__links {
    display: flex;
    align-items: center;

    @include breakpoint('s') {
      flex-direction: column;
    }

    a {
      color: #646464;
      font-weight: 500;
      margin: 0 10px;
    }

    &__sm {
      display: flex;

      @include breakpoint('s') {
        margin-top: 1em;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        text-align: center;
        background: #fff;
        border-radius: 50%;
      }
    }
  }
}
