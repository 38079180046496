.media-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 50px;

  img {
    margin-bottom: 1rem;
  }

  &__title {
    margin-top: 0;
    line-height: 1.3em;
  }
}
