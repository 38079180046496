@import '../../styles/colors.scss';
@import '../../styles/breakpoint.mixin.scss';

.media-items {
  .row:first-of-type {
    margin-bottom: 50px;
  }

  &__header {
    p {
      margin-bottom: 0;
    }

    @include breakpoint('s') {
      margin-bottom: 20px;
    }
  }

  &__search {
    display: flex;
    align-items: flex-end;
  }
  &__no-result {
    p {
      color: $color-red;
      margin: 0;

      span {
        font-weight: bold;
      }
    }
  }
}
