@import '../../styles/colors.scss';

// TODO: Sidebar breiter, mehr abstand Siderbar <-> Content

.license-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 2;

  &__inner {
    width: 100%;
    max-width: 760px;
    padding: 30px;
  }

  &__button {
    margin-top: 24px;
    background: $color-blue-new;
    color: #fff;
    outline: 0;
    padding: 10px 80px;
    border: 2px solid $color-blue-new;
    border-radius: 16px;
    text-align: center;
    font-size: 1em;
    cursor: pointer;

    &:hover {
      background: $color-blue-new-hover;
      border-color: $color-blue-new-hover;
    }

    &:active {
      background: $color-blue-new-active;
      border-color: $color-blue-new-active;
    }
  }
}
