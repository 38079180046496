body {
  font-family: 'SonnenText', -apple-system, BlinkMacSystemFont, Helvetica Neue,
    sans-serif;
  font-weight: 300;
  font-size: 0.937rem;
  color: $color-ground-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-family: SonnenDisplay;
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0.75rem 0;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 0.875rem;
  text-transform: uppercase;
}
