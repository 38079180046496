@import '../../styles/breakpoint.mixin.scss';

.user-avatar {
  display: flex;
  align-items: center;

  &.--align-center {
    justify-content: center;
  }
  &.--align-left {
    justify-content: start;
  }
  &.--align-right {
    justify-content: end;
  }

  &__trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__avatar {
    width: 34px;
    height: 34px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 50%;
    margin-left: 10px;

    .--prepend & {
      margin-left: 0;
      margin-right: 12px;
    }
  }

  &__caret {
    margin-top: 8px;
    padding: 4px 0px 4px 4px;
  }
}
