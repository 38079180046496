.mediaPagePublic {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 2rem;
    }
  }

  header,
  footer {
    flex-shrink: 0;
    z-index: 100;
  }
}
